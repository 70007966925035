import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import LoginLayout from "src/layouts/LoginLayout";
import DashboardLayout from "src/layouts/DashboardLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Login")),
  },
  {
    exact: true,
    path: "/register",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Register")),
  },
  {
    exact: true,
    path: "/registrationRealtime",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/RealTimeRegistration")),
  },
  {
    exact: true,

    path: "/change-password",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Auth/ChangePassword")),
  },
  {
    exact: true,
    path: "/kyc-list",

    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/KycManagement/KycList")
    ),
  },
  {
    exact: true,

    path: "/subscription-list",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubscriptionManage/Subscription")
    ),
  },

  {
    exact: true,
    path: "/dashboard",
    //
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/DashboardManagement/Dashboard")
    ),
  },

  {
    exact: true,
    path: "/view-kyc",

    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/KycManagement/ViewKyc")
    ),
  },
  {
    exact: true,
    path: "/user-list",

    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/UserList")
    ),
  },
  {
    exact: true,
    path: "/view-user",

    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/ViewUser")
    ),
  },
  {
    exact: true,
    path: "/forget",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Forget")),
  },

  {
    exact: true,
    path: "/profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Profile")),
  },
  {
    exact: true,
    path: "/edit-profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/EditProfile")),
  },

  {
    exact: true,
    path: "/verify-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Verifyotp")),
  },
  {
    exact: true,
    path: "/otp-verification",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/OtpVerification")),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/ResetPassword")),
  },
  {
    exact: true,
    path: "/view-subscription",

    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubscriptionManage/ViewSubscription")
    ),
  },
  {
    exact: true,
    //
    path: "/subscription-management",

    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubscriptionManage/Subscription")
    ),
  },
  {
    exact: true,
    //
    path: "/add-subscription",

    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubscriptionManage/AddSubscibe")
    ),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
